import {combineReducers} from 'redux'
import {
    CLOSE_FILE,
    SELECT_FILE,
    REQUEST_FILE,
    RECEIVE_FILE,
    NEW_FILE,
    TOGGLE_NEW_FILE_MODAL
} from '../actions';

let welcomeText = require('../../languages/Swift').text;

function files(state = {
    shouldShowNewFileModal: false,
    selectedFile: 'SpeedTyper.swift',
    filesByKey: {
        "SpeedTyper.swift": {
            name: "SpeedTyper.swift",
            text: welcomeText,
            isLoading: false,
            language: "swift"
        }
    },
    files: [
        {
            name: "SpeedTyper.swift"
        }
    ]
}, action) {
    //console.log("Action: " + action.type, action);
    switch (action.type) {
        case CLOSE_FILE:
            let closeFileName = action.fileName;
            let closeFiles = state.files.slice().filter(file => {
                return file.name !== closeFileName;
            });

            if (closeFiles.length === 0) {
                // Always require one file!
                return state;
            }

            let closeFilesByKey = Object.assign({}, state.filesByKey);
            delete closeFilesByKey[closeFileName];

            let closeObj = Object.assign({}, state, {
                files: closeFiles,
                filesByKey: closeFilesByKey
            });

            if (state.selectedFile === closeFileName && closeFiles.length > 0) {
                closeObj.selectedFile = closeFiles[closeFiles.length - 1].name;
            }

            return closeObj;
        case NEW_FILE:
            let fileName = action.fileName;
            let newFile = {
                name: fileName,
                text: "",
                language: action.language,
                isLoading: true
            };

            let newFilesByKey = Object.assign({}, state.filesByKey);
            newFilesByKey[fileName] = newFile;

            let newFiles = state.files.slice();
            newFiles.push(newFile);

            return Object.assign({}, state, {
                files: newFiles,
                filesByKey: newFilesByKey,
                selectedFile: action.fileName,
                shouldShowNewFileModal: false
            });
        case TOGGLE_NEW_FILE_MODAL:
            return Object.assign({}, state, {
                shouldShowNewFileModal: action.shouldShowNewFileModal
            });
        case SELECT_FILE:
            return Object.assign({}, state, {
                selectedFile: action.fileName
            });
        case REQUEST_FILE:
            return state;
        case RECEIVE_FILE:
            let receivedFileName = action.fileName;
            let oldFile = state.filesByKey[receivedFileName]
            let newReceivedFile = Object.assign({}, oldFile, {
                text: action.data.text,
                isLoading: false
            });
            let newReceivedFilesByKey = Object.assign({}, state.filesByKey);
            newReceivedFilesByKey[receivedFileName] = newReceivedFile;

            return Object.assign({}, state, {
                filesByKey: newReceivedFilesByKey
            });
        default:
            return state
    }
}

const rootReducer = combineReducers({
    files
});

export default rootReducer