import fetch from 'cross-fetch';

//let backendHost = `//localhost:3500`;
let backendHost = `//code-server.appi.no`;

export const SELECT_FILE = "SELECT_FILE";
export const selectFile = fileName => ({
    type: SELECT_FILE,
    fileName
});

export const REQUEST_FILE = "REQUEST_FILE";
function requestFile(language, fileName) {
    return {
        type: REQUEST_FILE,
        language,
        fileName
    };
}

export const RECEIVE_FILE = "RECEIVE_FILE";
function receiveFile (language, fileName, json) {
    return {
        type: RECEIVE_FILE,
        language,
        fileName,
        data: json
    }
}

export const NEW_FILE = "NEW_FILE";
export function newFile(language, fileName) {
    return {
        type: NEW_FILE,
        fileName,
        language
    }
}

export const CLOSE_FILE = "CLOSE_FILE";
export function closeFile(fileName) {
    return {
        type: CLOSE_FILE,
        fileName
    }
}

export const TOGGLE_NEW_FILE_MODAL = "TOGGLE_NEW_FILE_MODAL";
export function toggleNewFileModal(shouldShowNewFileModal) {
    return {
        type: TOGGLE_NEW_FILE_MODAL,
        shouldShowNewFileModal
    }
}

export function fetchFile(language, fileName) {
    return dispatch => {
        dispatch(requestFile(language, fileName));
        return fetch(`${backendHost}/language/${language}`)
            .then(response => response.json())
            .then(json => dispatch(receiveFile(language, fileName, json)))
    }
}