import React from 'react';
import { default as SyntaxHighlighter} from 'react-syntax-highlighter';
import syntaxStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/xcode';
import './TypingContainer.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { toggleNewFileModal } from './redux/actions'

let lettersPerKeyPress = 2;
const deleteAmount = 1;

let KeyboardEventHandler = null;

class TypingContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: "SpeedTyper.swift",
            files: {
                "SpeedTyper.swift": {
                    keyPressAmount: 20,
                    typedText: ""
                }
            }
        };
        this.handleKeyEvent = this.handleKeyEvent.bind(this);
        this.handleNewFile = this.handleNewFile.bind(this);
        this.resetEditor = this.resetEditor.bind(this);
    }

    resetEditor() {
        //console.log("Reset editor!")
    }

    handleNewFile() {
        let dispatch = this.props.dispatch;
        dispatch(toggleNewFileModal(true));
    }

    handleKeyEvent(key, event) {
        event.preventDefault();

        if (key === "alt+1") {
            lettersPerKeyPress = 1;
            return;
        }

        if (key === "alt+2") {
            lettersPerKeyPress = 2;
            return;
        }

        if (key === "alt+3") {
            lettersPerKeyPress = 5;
            return;
        }

        if (key === "alt+4") {
            lettersPerKeyPress = 10;
            return;
        }

        if (key === "alt+5") {
            lettersPerKeyPress = 100;
            return;
        }

        if (key === 'ctrl+n') {
            this.handleNewFile();
            return;
        }

        const fileName = this.state.selectedFile;
        const fileState = this.state.files[fileName] || {
            keyPressAmount: 0,
            typedText: ""
        };

        let keyPressAmount = fileState.keyPressAmount || 0;
        let newText = fileState.typedText || "";
        let newKeyPressAmount = fileState.keyPressAmount || 0;
        const fileText = this.props.file.text || "";

        if (key === 'backspace') {
            if (keyPressAmount - deleteAmount > 0) {
                newText = fileText.substring(0, keyPressAmount - deleteAmount);
                newKeyPressAmount = keyPressAmount - deleteAmount;
            } else {
                // Empty!
                newText = "";
                newKeyPressAmount = 0;
            }
        } else if (fileText.length > keyPressAmount) {
            let amountOfLetters = lettersPerKeyPress;
            if (keyPressAmount + lettersPerKeyPress > fileText.length) {
                amountOfLetters = fileText.length - keyPressAmount
            }

            newText = fileText.substring(0, keyPressAmount + amountOfLetters);
            newKeyPressAmount = keyPressAmount + amountOfLetters
        }

        let newFilesState = Object.assign({}, this.state.files, {
            [fileName]: {
                keyPressAmount: newKeyPressAmount,
                typedText: newText
            }
        });

        this.setState({
            files: newFilesState
        });
    }

    convertedKey(key) {
        switch (key) {
            case " ":
                return '\u00A0';
            default:
                return key;
        }
    }

    generateTextFromKeyPress(key) {
        if (key === '\n') {
            return <br/>;
        } else {
            return <span>{this.convertedKey(key)}</span>;
        }
    }

    generateTextFromKeyPresses(keyPresses) {
        return keyPresses.map(key => this.generateTextFromKeyPress(key));
    }

    scrollToBottom = () => {
        if (this.bottomElement) {
            this.bottomElement.scrollIntoView({behavior: "smooth"});
        }
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps, prevState) {
        this.scrollToBottom();

        if (prevProps.file && prevProps.file.name !== this.props.file.name) {
            this.resetEditor();
            this.setState({
                selectedFile: this.props.file.name
            });
        }
    }

    render() {
        let keyboardHandler = null;
        if (typeof window !== 'undefined') {
            KeyboardEventHandler = require('react-keyboard-event-handler');
            keyboardHandler = <KeyboardEventHandler
                handleKeys={[
                    'alphanumeric',
                    'backspace',
                    'ctrl+n',
                    'meta+n',
                    'alt+1',
                    'alt+2',
                    'alt+3',
                    'alt+4',
                    'alt+5'
                ]}
                onKeyEvent={this.handleKeyEvent}/>
        }

        if (!this.props.file) {
            return null;
        }

        if (this.props.file && this.props.file.isLoading) {
            return <p>Loading editor...</p>;
        }

        const fileName = this.props.file.name;
        let language = "swift";
        if (this.props.file && this.props.file.language) {
            language = this.props.file.language;
        }

        let typedText = "";
        if (this.state.files && this.state.files[fileName]) {
            typedText = this.state.files[fileName].typedText;
        }

        return (
            <div className="TypingContainer">
                <div className={'textarea'}>
                    <SyntaxHighlighter codeTagProps={{
                        className: "speedtyper-code-container"
                    }}
                                       showLineNumbers={true}
                                       language={language}
                                       lineNumberContainerStyle={{
                                           minWidth: 30,
                                           float: 'left',
                                           paddingRight: '5px',
                                           color: '#333'
                                       }}
                                       style={syntaxStyle}
                    >

                        {typedText}
                    </SyntaxHighlighter>
                    <span ref={(el) => { this.bottomElement = el; }}>&nbsp;</span>
                </div>
                {keyboardHandler}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {files} = state;
    if (!files) {
        return {};
    }

    let file = files.filesByKey[files.selectedFile];

    return {
        file
    };
};

export default connect(mapStateToProps)(withRouter(TypingContainer));
