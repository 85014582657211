import React from 'react';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import SpeedTyper from './SpeedTyper';
import './App.css';

const App = () => (
    <Switch>
        <Route path="/" render={
            (props) => <SpeedTyper {...props} />
        }/>
    </Switch>
);

export default App;
