import React from 'react';
import './SpeedTyper.css';
import TypingContainer from './TypingContainer';
import FileList from "./FileList";
import NewFile from "./NewFile";

class SpeedTyper extends React.Component {
  render() {
    return (
      <div className="SpeedTyper">
        <FileList />
        <TypingContainer />
        <NewFile />
      </div>
    );
  }
}

export default SpeedTyper;
