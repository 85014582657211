import React from 'react';
import './NewFile.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {fetchFile, newFile, toggleNewFileModal} from "./redux/actions";
import { languages } from "./languages/Languages";
let KeyboardEventHandler = null;


class NewFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filename: "",
            selectedLanguage: ""
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleKeyEvent = this.handleKeyEvent.bind(this);
        this.handleNewFile = this.handleNewFile.bind(this);
        this.handleFilenameChange = this.handleFilenameChange.bind(this);
        this.handleFileTypeChange = this.handleFileTypeChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.shouldShowNewFileModal && this.props.shouldShowNewFileModal) {
            this.filenameInput.focus();
        }
    }

    handleKeyEvent(key, event) {
        event.preventDefault();

        if (key === 'enter') {
            this.handleNewFile();
            return;
        }

        if (key === 'esc') {
            this.handleClose();
            return;
        }
    }

    getFileTypeFromFilename(filename) {
        let splitFilename = filename.split('.');
        if (splitFilename.length <= 1) {
            return null;
        }

        const splitFileType = splitFilename[splitFilename.length - 1].toLowerCase();

        let foundLanguages = languages.filter(language => {
           return language.fileType === splitFileType
        });

        if (foundLanguages.length > 0) {
            return foundLanguages[0];
        }
    }

    getLanguageFromLanguageId(languageId) {
        let foundLanguages = languages.filter(language => {
            return language.id === languageId
        });

        if (foundLanguages.length > 0) {
            return foundLanguages[0];
        }

        return null;
    }

    handleFilenameChange(evt) {
        let filename = evt.target.value;
        let newState = {
            filename
        };

        let language = this.getFileTypeFromFilename(filename);
        if (language) {
            newState.selectedLanguage = language;
        }

        this.setState(newState);
    }

    handleFileTypeChange(evt) {
        let languageId = evt.target.value;

        let language = this.getLanguageFromLanguageId(languageId);
        if (!language) {
            return null;
        }

        let newState = {
            selectedLanguage: language
        };

        this.setState(newState);
    }

    handleClose() {
        let dispatch = this.props.dispatch;
        dispatch(toggleNewFileModal(false));
        this.setState({
            filename: ""
        });
    }

    handleCancel() {
        this.handleClose()
    }

    handleNewFile() {
        // TODO: Make modal where you pick language and file name instead of hardcoding.
        let dispatch = this.props.dispatch;
        let language = this.state.selectedLanguage;

        if (!language) {
            language = this.getLanguageFromLanguageId("swift");
        }

        let languageId = language.id;
        let fileName = `${this.state.filename}`;

        if (!fileName.endsWith(`.${language.fileType}`)) {
            fileName += `.${language.fileType}`;
        }

        dispatch(newFile(languageId, fileName));
        dispatch(fetchFile(languageId, fileName));
        this.handleClose()
    }

    renderLanguages(languages, selectedLanguage) {
        let languagesToRender = languages.map(language => {
            return (
                <option key={language.id} value={ language.id }>{ language.name }</option>
            )
        });

        return languagesToRender;
    }

    render() {
        if (!this.props.shouldShowNewFileModal) {
            return null;
        }
        let keyboardHandler = null;
        if (typeof window !== 'undefined') {
            KeyboardEventHandler = require('react-keyboard-event-handler');
            let selectedLanguage = this.state.selectedLanguage;
            let languagesOutput = this.renderLanguages(languages, selectedLanguage);
            keyboardHandler = (
                <KeyboardEventHandler handleKeys={['enter', 'esc']}
                                      isExclusive={true}
                                      onKeyEvent={this.handleKeyEvent}>
                    <div className={"new-file-modal-inner-container"}>
                        <div className={'filename-container'}>
                            <div><label htmlFor={'filename'}>Filename</label></div>
                            <div>
                                <input value={this.state.filename}
                                       onChange={this.handleFilenameChange}
                                       ref={(input) => {
                                           this.filenameInput = input;
                                       }}
                                       id={'filename'}
                                       type={'text'}
                                       placeholder={"Filename"}/>
                            </div>
                            Not sure about the filename? We can <button>generate it!</button>
                        </div>

                        <div className={'language-container'}>
                            <div><label htmlFor={'language'}>Programming language</label></div>
                            <div>
                                <select onChange={this.handleFileTypeChange} id={'language'} name="language" value={selectedLanguage.id}>
                                    { languagesOutput }
                                </select>
                            </div>
                        </div>

                        <div className={'button-container'}>
                            <button onClick={this.handleCancel}>Cancel</button>
                            <button onClick={this.handleNewFile}>Done</button>
                        </div>
                    </div>
                </KeyboardEventHandler>
            )
        }

        return (
            <div className="new-file-modal">
                {keyboardHandler}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {files} = state;
    if (!files) {
        return {};
    }

    return {
        shouldShowNewFileModal: files.shouldShowNewFileModal
    };
};

export default connect(mapStateToProps)(withRouter(NewFile));
