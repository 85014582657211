// PLACEHOLDER, not actual code being used anywhere!
let output = `import Foundation

class SpeedTyper {

    let toString: String = "to"
    let speedTyper: String = "SpeedTyper!"
    
    private func welcome() -> String {
        return "Welcome "
    }
    
    private func to() -> String {
        return toString;
    }
    
    init() {
        print(welcome(), to(), speedTyper);
    }
}

SpeedTyper.init();
`;

let a = {
    text: output
};

module.exports = a;