import React from 'react';
import {connect} from "react-redux";
import {closeFile, selectFile, toggleNewFileModal} from "./redux/actions";
import {withRouter} from "react-router-dom";
import './FileList.css';

class FileList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.renderFiles = this.renderFiles.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.handleNewFile = this.handleNewFile.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    handleCloseFile(file) {
        let dispatch = this.props.dispatch;
        dispatch(closeFile(file.name));
    }

    handleNewFile() {
        let dispatch = this.props.dispatch;
        dispatch(toggleNewFileModal(true));
    }

    handleSelectFile(file) {
        let dispatch = this.props.dispatch;
        dispatch(selectFile(file.name));
    }

    renderFiles(files, selectedFile) {
        if (!files) {
            return null;
        }

        return files.map(file => {
            let selectedClass = "";
            if (selectedFile === file.name) {
                selectedClass = " selected";
            }

            return (
                <span onClick={
                    () => {
                        this.handleSelectFile(file);
                    }}
                      key={file.name}
                      className={'file-list-item' + selectedClass}
                >
                    <span>
                        {file.name}
                    </span>

                    <svg
                        onClick={(evt) => {
                            evt.stopPropagation();
                            this.handleCloseFile(file);
                        }}
                        aria-hidden="true" focusable="false" data-icon="close-button" className="icon close-button"
                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path></svg>
                </span>
            )
        })
    }

    render() {
        let files = this.state.files;
        let selectedFile = this.props.files.selectedFile;
        let renderedFiles = this.renderFiles(this.props.files.files, selectedFile);
        return (
            <div className="file-list">
                {renderedFiles}
                <span className={"new-file"} onClick={this.handleNewFile}>+ New file</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {files} = state;
    if (!files) {
        return {};
    }

    return {
        files: files
    };
};

export default connect(mapStateToProps)(withRouter(FileList));