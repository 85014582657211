// TODO: Add support for languages to know file extensions and creating select forms etc


let languages = [
    {
        id: "swift",
        name: "Swift",
        fileType: "swift",
        highlightName: "swift"
    },
    {
        id: "javascript",
        name: "JavaScript",
        fileType: "js",
        highlightName: "javascript"
    },
    {
        id: "cpp",
        name: "C++",
        fileType: "cpp",
        highlightName: "cpp"
    },
    {
        id: "java",
        name: "Java",
        fileType: "java",
        highlightName: "java"
    },
    {
        id: "plaintext",
        name: "Textfile",
        fileType: "txt",
        highlightName: "plaintext"
    },
    {
        id: "kotlin",
        name: "Kotlin",
        fileType: "kt",
        highlightName: "kotlin"
    },
    {
        id: "csharp",
        name: "C#",
        fileType: "cs",
        highlightName: "csharp"
    },
    {
        id: "python",
        name: "Python",
        fileType: "py",
        highlightName: "python"
    },
    {
        id: "php",
        name: "PHP",
        fileType: "php",
        highlightName: "php"
    },
    {
        id: "go",
        name: "Go",
        fileType: "go",
        highlightName: "go"
    }
];

module.exports = {
    languages
};
